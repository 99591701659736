import React from "react";
import styled from "@emotion/styled/macro";
import { COLORS } from "../Theme";

const Container = styled.div`
  background-color: ${COLORS.GREY2};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 5vh;
  padding-top: 5vh;
`;

const Content = styled.div`
  background-color: ${COLORS.GREY0};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  padding: 20px;
`;

const HeaderText = styled.div`
  font-size: 2rem;
  padding-bottom: 20px;
`;

const LinkText = styled.div`
  font-size: 1.2rem;
  white-space: pre-line;
  line-height: 1.5;
  text-decoration: underline;
  color: blue;
`;

const BodyText = styled.div`
  font-size: 1.2rem;
  white-space: pre-line;
  line-height: 1.5;
`;

const Pricing = () => {
  const guide = "./Price guide.pdf";

  var text = `Call Gill on 01799 531522 or email on gilllairdchairs@gmail.com for a free quote
  `;

  return (
    <Container id="pricing">
      <Content>
        <HeaderText>Pricing</HeaderText>
        <LinkText>
          <a
            href={guide}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Click here for pricing guide
          </a>
        </LinkText>
        <BodyText>{text}</BodyText>
      </Content>
    </Container>
  );
};

export default Pricing;
