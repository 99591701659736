export const COLORS = {
  RED: "#df2966",
  GREEN: "#0cce6b",
  BLUE: "#2ab7ca",
  WHITE: "#fbfaf8",
  BLACK: "#0d1821",
  GREY0: "#FDFDFD",
  GREY1: "#FDFDFD",
  GREY2: "#D8D8D8",
  GREY3: "#999999",
  BLACKT: "#80000000",
};
